// @flow

import './DesktopHeader.scss';

import { BOILogoNoSpace, Typeahead } from '@boi/core/lib';
import { faLocationArrow,faSignInAlt, faSignOutAlt, faUser,faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { darkBlue, greyDark } from '../../constants/colors';
import { USER_BRANCH } from '../../constants/sessionStorage/userStorageIdentifiers';
import { getItemFromSessionStorage, isAgent } from '../../helpers';
import { getAgentName } from '../../helpers/AgentCheck';
import { routes } from '../../routes';
import { updateUserProfileBranch } from '../../services/profile/profile';
import UserContext from '../../UserContext';
import { UserProfileContext } from '../../UserProfile';
import PopOverWrapper from '../PopOverWrapper/PopOverWrapper';
import SubHeading from './SubHeading';

const DesktopHeader = () => {

  const className = 'c-DesktopHeader';
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const { cognitoUser, branchList } = useContext(CognitoContext);
  const { userProfile } = useContext(UserProfileContext);
  const { user, userDispatch } = useContext(UserContext);
  const [popOverVisible, setPopOverVisible] = useState(true);

  const getAccessUrl = () => {
    return isLoggedIn ?  popOverDropDown(): !isAgent() ? loggedInAndSignIn() : <></>
  };

  const titleHeader = () => {
    return (
      <>
        {isLoggedIn && 
          (
            <><span className={`${className}__loggedInLabel`}>{cognitoUser?.attributes?.given_name || getAgentName(cognitoUser) }</span><FontAwesomeIcon className={"faIconHover"} icon={faUser} size="1x" color={greyDark} /></>
          )
        }
      </>
    )
  }

  const branchHeader = () => {
    return (
      <>
        {isLoggedIn ? 
          <><span data-test-id='branch-name' className={`${className}__loggedInLabel`}>{user?.branchName.branch_name || 'Search Branch' }</span><FontAwesomeIcon className={"faIconHover"} icon={faLocationArrow} size="1x" color={greyDark} /></> : null
        }
      </>
    )
  }

  const loggedInAndSignIn = () => {
    return (
      <div className={`${className}__desktopNavigationMenus`}>
            <Link to={routes.signUp.url}>
              <div className={`${className}__logInLabel`}>Sign Up<span><FontAwesomeIcon className={"signupIcon"} icon={faUserAlt} size="1x" color={greyDark} /></span></div>
            </Link>
            <Link to={routes.loginPage.url}>
              <div className={`${className}__logInLabel`}>Login<span><FontAwesomeIcon className={"loginIcon"} icon={faSignInAlt} size="1x" color={greyDark} /></span></div>
            </Link>
      </div>
    )
  }

  const popOverDropDown = () => {
    return (
      <>
        {isAgent() && (
          <div className={`${className}__isAgentLocationAndNameHeader`}>
            <PopOverWrapper
              clickTitle={branchHeader()}
              visiblility={popOverVisible}
              updateVisibility={() => {
                setPopOverVisible(true)
              }}
            >
              <div>
              <Typeahead
                  data={branchList}
                  placeholder="Search Branch Type"
                  onChange={(selectedItem: any) => {
                    if(selectedItem) {
                        updateUserProfileBranch({branchName: selectedItem}).then((response: any) => {
                          if(response.branch_name) {
                            userDispatch({
                              type: 'setBranchName',
                              payload: { branchName:  response}
                            });
                          }
                          setPopOverVisible(false)
                        })
                    }
                  }}
              />
              </div>

            </PopOverWrapper>
            <PopOverWrapper
              clickTitle={titleHeader()}
              visiblility={popOverVisible}
              updateVisibility={() => {
                setPopOverVisible(true)
              }}
            >
              <Link to={routes.logoutPage.url}>
                  <div className={`${className}__logoutLabel`}>Logout<span className={`${className}__logoutLabelIcon`}><FontAwesomeIcon className={`${className}__logoutIcon`} icon={faSignOutAlt} size="1x" color={greyDark} /></span></div>
              </Link>
            </PopOverWrapper>
          </div>
        )}
        {!isAgent() && (
          <>
            <PopOverWrapper
              clickTitle={titleHeader()}
              visiblility={popOverVisible}
              updateVisibility={() => {
                setPopOverVisible(true)
              }}
            >
              <Link to={routes.logoutPage.url}>
                  <div className={`${className}__logoutLabel`}>Logout<span className={`${className}__logoutLabelIcon`}><FontAwesomeIcon icon={faSignOutAlt} size="1x" color={greyDark} /></span></div>
              </Link>
            </PopOverWrapper>
          </>
        )}
      </>

    )
  }

  // eslint-disable-next-line complexity
  useEffect(() => {
    const branch = JSON.parse(getItemFromSessionStorage(USER_BRANCH))
    if (!(user?.branchName && Object.keys(user?.branchName).length > 0) && branch) {
      const userBranch = branch;
      userDispatch({
        type: 'setBranchName',
        payload: { branchName: userBranch }
      });
    }
  }, [user])

 useEffect(() => {
    setIsLoggedIn(!!cognitoUser)
 }, [cognitoUser])

  return (
    <>
      <div className={`${className}`}>
      <SubHeading routes={routes} userProfile={userProfile} isLoggedIn={isLoggedIn} />
        <div className={`${className}__logo`} >
          <BOILogoNoSpace color={darkBlue} />
        </div>
        {getAccessUrl()}
      </div>
    </>
  );
};

export default withRouter(DesktopHeader);
