/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import { basePath } from "../constants/commonConstants";
import { PAYLOADVALUE } from "../constants/retriveUserConstant";
import { makeRequest } from "../helpers";


export const getUsers = async (values: any) => {
  try {
    return await makeRequest({
      method: 'POST',
      url: `${basePath}/users`,
      payload: { [PAYLOADVALUE[`${values.dropdownValue}`]]: values.searchInput, starts_with: true }
    })
  } catch {
    return [];
  }
}


export const getPortfolioCodes = async(values: any) => {
  try {
    const response = await makeRequest({
      method: 'POST',
      url: `${basePath}/home/applied_match/search`,
      payload: { "third_party_identifier" : values.data.username }
    })
    if (response.error) {
      return [];
    } else {
      return response;
    }
  } catch {
    return [];
  }
}

export const matchUserToPortfolioCode = async(values: any) => {
  try {
    const response = await makeRequest({
      method: 'POST',
      url: `${basePath}/home/applied_match/match`,
      payload: values
    })
      return response;
  } catch {
    return [];
  }
}

export const unMatchUserToPortfolioCode = async(values: any) => {
  try {
    const response = await makeRequest({
      method: 'POST',
      url: `${basePath}/home/applied_match/unmatch`,
      payload: values
    })
      return response;
  } catch {
    return [];
  }
}
