import { HOME_INSURANCE_TYPE } from './insuranceTypeConstants';
import { commonPageStylesIdentifier } from './styleConstants';
import { CommonPageStylesDesktopBackButton } from './styleConstants/commonClassNames';

export const basePath = process.env.REACT_APP_API_ENDPOINT || '/';

export const noPurchasePolicy =
  'Your purchased policy is still processing or you have no insurance policies to manage.';

export const isAnalyticsNotEnabled =
  process.env.REACT_APP_MANIFEST_DIR !== 'ci' &&
  process.env.REACT_APP_MANIFEST_DIR !== 'dev';

export const ERROR_TEXT_LATEST_QUOTE =
  'We’re sorry, your search does not match any results. Please check the spelling and try again or try searching by the first word only.';

export const ERROR_TEXT_LATEST_QUOTE_BRANCH =
  'We’re sorry, your search does not match any results.';

export const desktopBackButtonClass = insuranceType => {
  return insuranceType === HOME_INSURANCE_TYPE
    ? `${commonPageStylesIdentifier} ${CommonPageStylesDesktopBackButton}`
    : `${commonPageStylesIdentifier}__hideOnDesktop`;
};
