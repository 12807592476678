import {
  aboutYouFormIdentifier,
  yourCarCoverFormIdentifier,
  yourCarFormIdentifier,
  yourTravelCoverFormIdentifier,
  yourTravelFormIdentifier
} from '../../components/Forms/CommonFiles/commonFormConstants';

export const getQuoteFormStructure = {
  car: {
    [aboutYouFormIdentifier]: [
      'title',
      'firstName',
      'lastName',
      'dob',
      'addressLine1',
      'addressLine2',
      'addressLine3',
      'addressTown',
      'addressCounty',
      'addressEircode',
      //@amanda removed this on 30th October 2019
      // 'isThisYourPostalAddress',
      // 'postalAddressLine1',
      // 'postalAddressLine2',
      // 'postalAddressLine3',
      // 'postalAddressTown',
      // 'postalAddressCounty',
      // 'postalAddressEircode',
      'phoneNo',
      'email',
      'employmentStatus',
      'mainOccupation',
      'driversLicenseType',
      'passedTestInIrelandOrUk',
      'lastFiveYearsExperience',
      'yearsHeld',
      'bankOfIrelandCustomer',
      'bankOfIrelandHomeInsurancePolicy',
      'promoCode',
      'insuranceType'
    ],
    [yourCarFormIdentifier]: [
      'carReg',
      'risk',
      'carModel',
      'carMake',
      'carVersion',
      'carYear',
      'carFuelType',
      'carEngineSize',
      'carABICode',
      'carPurchased',
      'vehicleValue',
      'carUsedForWork',
      'annualKm',
      'drivingExpType',
      'noClaimsBonus',
      'isManualSearch'
    ],
    [yourCarCoverFormIdentifier]: [
      'policyStart',
      'coverType',
      'motorClaims',
      'claimsCount',
      'mainDriverPenaltyPointOffences',
      'mainDriverPenaltyPointOffenceCount',
      'mainDriverDisqualifiedOrConvicted',
      'hasAdditionalDrivers',
      'additionalDrivers',
      'acceptedTermsAndConditions',
      'disclosureRequirements',
      'motorUserAgreement'
    ]
  },
  travel: {
    [aboutYouFormIdentifier]: [
      'title',
      'firstName',
      'lastName',
      'dob',
      'addressLine1',
      'addressLine2',
      'addressLine3',
      'addressCounty',
      'addressEircode',
      'phoneNo',
      'email',
      'coverAbroad',
      'provider',
      'bankOfIrelandCustomer',
      'promoCode'
    ],
    [yourTravelFormIdentifier]: [
      'tripType',
      'travelFromDate',
      'travelToDate',
      'destinationZone',
      'destinationCountry',
      'insuredPerson',
      'numOfChildren',
      'partnerFirstName',
      'partnerLastName',
      'partnerDOB'
    ],
    [yourTravelCoverFormIdentifier]: [
      'carHireExcess',
      'winterSportsExcess',
      'businessExpenseExcess',
      'dataProtectionConsent',
      'chubbTOBConsent'
    ]
  }
};

export const inFormStructure = (field, insuranceType, formIdentifier) => {
  const formStructureArray =
    getQuoteFormStructure[insuranceType] &&
    getQuoteFormStructure[insuranceType][formIdentifier]
      ? getQuoteFormStructure[insuranceType][formIdentifier]
      : [];

  return formStructureArray.lastIndexOf(field) !== -1;
};
