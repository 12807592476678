/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import '../../styles/commonPageStyles.scss';
import './ViewMultipleQuotesPage.scss';

import { Card, Text, TitleWithUnderLine } from '@boi/core/lib';
import React, { useEffect, useState } from 'react';
import type { Location, RouterHistory } from 'react-router-dom';

import { MobileBackButton } from '../../components/MobileBackButton';
import ProgressBar from '../../components/ProgressBar';
import QuoteOverview from '../../components/QuoteOverview';
import {
  commonPageStylesIdentifier,
  GET_QUOTE_RESPONSE_STORAGE,
  GET_QUOTE_VALUES_STORAGE, RECALCULATED_QUOTE_VALUES_STORAGE,
  SELECTED_MOTOR_QUOTE_STORAGE
} from '../../constants';
import { CAR_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { JOURNEY_YOUR_QUOTE, MOTOR_TRAVEL_JOURNEY, STAGES_MOTOR_TRAVEL_JOURNEY } from '../../constants/journey';
import { getObjectFromSessionStorage, redirectOnBack,removeFromSessionStorage, saveInSessionStorage } from '../../helpers';
import { routes } from '../../routes';
import type { MotorQuoteResponseType } from '../../types';
import { getShowUpToFeatureNumber, listOfFeatures } from '../MotorViewQuote/ListOfMotorFeatures';

interface Props {
  history: RouterHistory;
  location: Location;
}

const popularQuoteType = 'popular';
const prestigeQuoteType = 'prestige';

const ViewMultipleQuotesPage = (props: Props) => {

  const className = 'c-ViewMultipleQuotePage';
  // TODO: @MichaelDalton to be added back in next PR
  const { history } = props;
  
  useEffect(() => {
    redirectOnBack(history, routes.getQuoteThankYouPage.url, `${routes.getGenericQuotePage.url}car`)
  }, [])

  const [popularQuoteTotal, setPopularQuoteTotal] = useState(0);
  const [prestigeQuoteTotal, setPrestigeQuoteTotal] = useState(0);
  const [carRegistration, setCarRegistration] = useState('');
  const [carInsuranceType, setCarInsuranceType] = useState('');

  useEffect(() => {
    removeFromSessionStorage(`car${RECALCULATED_QUOTE_VALUES_STORAGE}`);
    getQuoteResponse();
    setQuoteValues();
  }, []);

  const getQuoteResponse = () => {
    const responseContainer = getObjectFromSessionStorage(`${CAR_INSURANCE_TYPE}${GET_QUOTE_RESPONSE_STORAGE}`);
    if (responseContainer.response) {
      setQuoteDetailsFromResponse(responseContainer.response);
    } else {
      setPopularQuoteTotal(0);
      setPrestigeQuoteTotal(0);
    }
  };

  const setQuoteValues = () => {
    const motorQuoteValues = getObjectFromSessionStorage(`${CAR_INSURANCE_TYPE}${GET_QUOTE_VALUES_STORAGE}`);
    if (motorQuoteValues) {
      setCarRegistration(motorQuoteValues.carReg || '');
      setCarInsuranceType(motorQuoteValues.coverType || '');
    }
  };

  const setQuoteDetailsFromResponse = (quoteModel: MotorQuoteResponseType = {}) => {
    if (quoteModel.quote_reference) {
      setPopularQuoteTotal(getQuoteTotal(popularQuoteType, quoteModel));
      setPrestigeQuoteTotal(getQuoteTotal(prestigeQuoteType, quoteModel));
    } else {
      setPopularQuoteTotal(0);
      setPrestigeQuoteTotal(0);
    }
  };

  const getQuoteTotal = (quoteType: string, quoteModel: MotorQuoteResponseType) => {
    return quoteModel[quoteType] && quoteModel[quoteType].total_amount ? quoteModel[quoteType].total_amount : 0;
  };

  const goToViewQuotePage = (selectedQuoteType: string) => {
    const state = getObjectFromSessionStorage(`${CAR_INSURANCE_TYPE}${GET_QUOTE_RESPONSE_STORAGE}`);
    state.selectedQuoteType = selectedQuoteType;
    saveInSessionStorage(SELECTED_MOTOR_QUOTE_STORAGE, JSON.stringify(state));
    props.history.push({
      state,
      pathname: '/wallet/viewQuote/car'
    });
  };

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} pageJumpCount={-2} />
      </div>
      <ProgressBar stage={JOURNEY_YOUR_QUOTE} stages={STAGES_MOTOR_TRAVEL_JOURNEY} labelText={MOTOR_TRAVEL_JOURNEY}/>
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Your Quotes</TitleWithUnderLine>
      </div>
      <Text size="inherit" className={`${className}__subHeading`}>
        for {carRegistration}
      </Text>
      <div className={`${className}__cards`}>

        <Card margin={'0 60px 20px 0'}>
          <QuoteOverview
            quoteTitle={'Prestige'}
            quoteAmount={prestigeQuoteTotal}
            quoteFeatures={listOfFeatures(carInsuranceType, 'prestige')}
            showUpToFeature={getShowUpToFeatureNumber('prestige', carInsuranceType)}
            onMoreInfoClick={() => goToViewQuotePage(prestigeQuoteType)}
            underwrittenClass={'Prestige'}
          />
        </Card>


        <Card margin={'0'}>
          <QuoteOverview
            quoteTitle={'Popular'}
            quoteAmount={popularQuoteTotal}
            quoteFeatures={listOfFeatures(carInsuranceType, 'popular')}
            showUpToFeature={getShowUpToFeatureNumber('popular', carInsuranceType)}
            onMoreInfoClick={() => goToViewQuotePage(popularQuoteType)}
            underwrittenClass={'Popular'}
          />
        </Card>

      </div>
    </div>
  );
};

export default ViewMultipleQuotesPage;
