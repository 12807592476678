import {
  AccordionCard,
  Button,
  Title,
  TitleWithUnderLine
} from '@boi/core/lib';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../../CognitoUtils';
import {
  commonPageStylesIdentifier,
  HOME_QUOTE_THANK_YOU_STORAGE
} from '../../../constants';
import {
  NO_PREPOPULATION,
  PREPOPULATION_PREFORMED
} from '../../../constants/home';
import { HOME_INSURANCE_TYPE } from '../../../constants/insuranceTypeConstants';
import { JOURNEY_ABOUT_YOU } from '../../../constants/journey';
import {
  GET_QUOTE_VALUES_STORAGE,
  PAYMENT_SUCCESS,
  POLICY_RENEWAL,
  SUBMITTED_GET_QUOTE_STORAGE
} from '../../../constants/sessionStorage/genericStorageIdentifiers';
import { HOME_LATEST_QUOTE_STORAGE } from '../../../constants/sessionStorage/homeStorageIdentifiers';
import {
  AGENT_CLICKED_REFERENCE_QUOTES,
  AGENT_SELECTED_QUOTE,
  BOI_WALLET_TOWN_COUNTY_DATA,
  BOI_WALLET_USER_NAME_STORAGE
} from '../../../constants/sessionStorage/userStorageIdentifiers';
import {
  getItemFromSessionStorage,
  getObjectFromSessionStorage,
  isAgent,
  removeFromSessionStorage,
  saveInSessionStorage
} from '../../../helpers';
import {
  saveDataInLatestQuote,
  scrollToFirstError
} from '../../../helpers/HomeCommonMethods';
import { aboutYouObjInitialValues } from '../../../pages/GetQuotePage/InitalValues';
import useGetQuotePageValidity from '../../../pages/GetQuotePage/useGetQuotePageValidity';
import useSectionOpenedStatus from '../../../pages/GetQuotePage/useSectionOpenedStatus';
import { routes } from '../../../routes';
import { getTownAndCounty } from '../../../services/homeServices';
import UserContext from '../../../UserContext';
import Banner from '../../Banner/Banner';
import LegalText from '../../LegalText';
import { MobileBackButton } from '../../MobileBackButton';
import ProgressBar from '../../ProgressBar';
import FormikAboutYouForm from './AboutYouForm';

const className = 'c-GetQuotePage';
const AboutYouComponent = ({ latestQuote, history }) => {
  const [prepopulationStatus, setPrepopulationStatus] = useState(
    NO_PREPOPULATION
  );

  let emailParam;

  const [aboutYouObj, setAboutYou] = useState(
    Object.assign({}, aboutYouObjInitialValues)
  );

  const [opened, handleFormOpen] = useSectionOpenedStatus();
  const [aboutYouFormTrackCount, setaboutYouFormTrackCount] = useState(0);
  const [triggerSubmitButton, setTriggerSubmitButton] = useState(false);
  const [townAndCountyData, setTownsAndCountyData] = useState([]);
  const [validity, handleFormSubmit] = useGetQuotePageValidity();
  const { userDispatch } = useContext(UserContext);
  const { cognitoUser } = useContext(CognitoContext);

  if (isAgent() && getItemFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES)) {
    emailParam = getItemFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES);
  }

  const updateFirstName = value => {
    userDispatch({
      type: 'setFirstName',
      payload: { firstName: value }
    });
    saveInSessionStorage(BOI_WALLET_USER_NAME_STORAGE, value);
  };
  const setAboutYouObjForMultipleUpdatedFields = updatedFields => {
    const newValues = { ...aboutYouObj, ...updatedFields };
    if (updatedFields.firstName !== undefined)
      updateFirstName(updatedFields.firstName.toString());
    setAboutYou(newValues);
  };

  const setAboutYouObjForUpdatedFields = (fieldName, value) => {
    const newValues = { ...aboutYouObj };
    newValues[fieldName] = value;
    if (fieldName === 'firstName') updateFirstName(value.toString());
    setAboutYou(newValues);
  };

  const setUpdatedAboutYouValues = async values => {
    setAboutYou(values);
    updateFirstName(values.firstName);
  };

  const loadAboutYou = (object, override) => {
    const replaceOrKeepValue = key =>
      key in object && object[key] !== '' ? object[key] : aboutYouObj[key];
    setAboutYou(
      Object.keys(aboutYouObj).reduce((newObj, key) => {
        newObj[key] = override ? object[key] : replaceOrKeepValue(key);
        return newObj;
      }, {})
    );
  };

  const loadAgentFormValues = forms => {
    saveInSessionStorage(HOME_QUOTE_THANK_YOU_STORAGE, JSON.stringify(forms));
    if (forms.aboutYou) loadAboutYou(forms.aboutYou, true);
    setPrepopulationStatus(PREPOPULATION_PREFORMED);
  };

  const personaliseUsingLatestQuoteFirstName = () => {
    if (latestQuote.aboutYou && latestQuote.aboutYou.firstName) {
      updateFirstName(latestQuote.aboutYou.firstName);
    }
  };

  const setTownsAndCounty = async () => {
    try {
      const townAndCountyData = await getTownAndCounty();
      saveInSessionStorage(
        BOI_WALLET_TOWN_COUNTY_DATA,
        JSON.stringify(townAndCountyData)
      );
      setTownsAndCountyData(townAndCountyData);
    } catch (e) {
      saveInSessionStorage(BOI_WALLET_TOWN_COUNTY_DATA, []);
      setTownsAndCountyData([]);
    }
  };

  const renderSubmitButton = () => (
    <div className={`${className}__button`}>
      <a
        data-ga
        id={`GetQuote__getCoveredButton`}
        data-testid={`GetQuote__getCoveredButton`}
      >
        <Button
          quaternary
          fluid
          onClick={() => {
            setaboutYouFormTrackCount(aboutYouFormTrackCount + 1);
            setTriggerSubmitButton(true);
            if (validity.aboutYou) {
              goToYourHomePage();
            } else setTimeout(scrollToFirstError, 1);
          }}
        >
          Next
        </Button>
      </a>
    </div>
  );

  // eslint-disable-next-line complexity
  const setHomeThankYouStorage = isLatest => {
    return {
      aboutYouObj: isLatest ? latestQuote.aboutYou : aboutYouObj,
      yourHomeObj:
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.yourHomeObj ||
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.yourHome ||
        null,
      yourCoverObj:
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.yourCoverObj ||
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.yourCover ||
        null,
      assumptions:
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.assumptions ||
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.assumptions ||
        null,
      extraQuestionsValues:
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.extraQuestionsValues ||
        getObjectFromSessionStorage(`${HOME_QUOTE_THANK_YOU_STORAGE}`)
          ?.assumptions ||
        null
    };
  };

  // eslint-disable-next-line complexity
  const goToYourHomePage = () => {
    const linkAddress = `${routes.getQuotePage.url}yourHome`;
    const quoteData = setHomeThankYouStorage(false);
    saveInSessionStorage(
      HOME_QUOTE_THANK_YOU_STORAGE,
      JSON.stringify(quoteData)
    );
    const sessionStorageLatestQuote = getObjectFromSessionStorage(
      `${HOME_LATEST_QUOTE_STORAGE}`
    );
    if (Object.keys(sessionStorageLatestQuote).length !== 0) {
      saveDataInLatestQuote(
        sessionStorageLatestQuote,
        'aboutYou',
        quoteData.aboutYouObj
      );
    }
    if (
      isAgent() &&
      getItemFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES)
    ) {
      removeFromSessionStorage(AGENT_CLICKED_REFERENCE_QUOTES);
    }
    history.push({
      pathname: linkAddress,
      state: quoteData
    });
  };

  const getFormStatus = formName => {
    if (opened[formName]) {
      return validity[formName] ? 'success' : 'warning';
    }
    return 'default';
  };

  const sessionStoredValues = getObjectFromSessionStorage(
    `${HOME_INSURANCE_TYPE}${GET_QUOTE_VALUES_STORAGE}`
  );

  // eslint-disable-next-line complexity
  const preloadForm = () => {
    const flag = getObjectFromSessionStorage(BOI_WALLET_TOWN_COUNTY_DATA);
    if (flag === null || Object.keys(flag).length === 0) {
      setTownsAndCounty();
    }
    const objMaker = (oldObj, newObj) =>
      Object.assign({}, oldObj, newObj || {});
    const hasValues = obj =>
      typeof obj === 'object' && Object.keys(obj).length > 0;
    const submitted = getItemFromSessionStorage(
      `${HOME_INSURANCE_TYPE}${SUBMITTED_GET_QUOTE_STORAGE}`
    );
    const values = getObjectFromSessionStorage(
      `${HOME_QUOTE_THANK_YOU_STORAGE}`
    );
    const unSubmitted = () => !submitted && hasValues(values);
    const submittedLatest = () =>
      hasValues(latestQuote) &&
      (!isAgent() || getObjectFromSessionStorage(AGENT_SELECTED_QUOTE)) &&
      !hasValues(values);
    const submittedSession = () => hasValues(sessionStoredValues) && !isAgent();
    if (unSubmitted() && !submittedLatest()) {
      setAboutYou(oldAboutYou =>
        objMaker(oldAboutYou, values.aboutYouObj || latestQuote.aboutYou)
      );
      setPrepopulationStatus(PREPOPULATION_PREFORMED);
    } else if (submittedLatest()) {
      setAboutYou(oldAboutYou => objMaker(oldAboutYou, latestQuote.aboutYou));
      const quoteData = setHomeThankYouStorage(true);
      saveInSessionStorage(
        HOME_QUOTE_THANK_YOU_STORAGE,
        JSON.stringify(quoteData)
      );
      setPrepopulationStatus(PREPOPULATION_PREFORMED);
    } else if (submittedSession()) {
      loadAboutYou(sessionStoredValues, false);
      setPrepopulationStatus(PREPOPULATION_PREFORMED);
    }
  };

  const getContent = () => {
    return (
      <div>
        <p>
          Use promocode <strong>BAWCODE2250</strong> to get a{' '}
          <strong>10% discount</strong> off Home Insurance exclusively online.
        </p>
        <h2>Features:</h2>
        <ul>
          <li>Free instalment plan</li>
          <li>Comprehensive cover</li>
          <li>Tailored options</li>
        </ul>
      </div>
    );
  };

  useEffect(() => {
    setTownsAndCounty();
    removeFromSessionStorage(POLICY_RENEWAL);
    removeFromSessionStorage(PAYMENT_SUCCESS);
  }, []);

  useEffect(() => {
    preloadForm();
  }, [latestQuote]);

  useEffect(() => {
    personaliseUsingLatestQuoteFirstName();
  }, [cognitoUser]);

  return (
    <div className={className}>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <MobileBackButton history={history} />
      </div>
      <ProgressBar stage={JOURNEY_ABOUT_YOU} />
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <TitleWithUnderLine>Get Quotes</TitleWithUnderLine>
      </div>
      <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
        <Banner>{getContent()}</Banner>
      </div>
      <div className={`${className}__subHeading`}>
        <Title type="h4" align="left" variant="black">
          Please complete the following:
        </Title>
      </div>
      <div className={`${className}__getQuoteAccordions`}>
        <div>
          <div className={`${className}__accordions`}>
            <AccordionCard
              id={`GetQuote__aboutYou`}
              label="About you"
              status={getFormStatus('aboutYou')}
              childContainerClass={`${className}__noPaddingFormContainer`}
              isOnState={true}
            >
              <FormikAboutYouForm
                aboutYouValid={validity.aboutYou}
                formOpened={handleFormOpen}
                validForm={handleFormSubmit}
                setUpdatedAboutYouValues={setUpdatedAboutYouValues}
                aboutYouValues={aboutYouObj}
                setAboutYouObjForMultipleUpdatedFields={
                  setAboutYouObjForMultipleUpdatedFields
                }
                setAboutYouObjForUpdatedFields={setAboutYouObjForUpdatedFields}
                isLoggedIn={!!cognitoUser}
                aboutYouFormTrackCount={aboutYouFormTrackCount}
                prepopulationStatus={prepopulationStatus}
                loadAgentFormValues={loadAgentFormValues}
                emailParam={emailParam}
                cognitoUser={cognitoUser}
                triggerSubmitButton={triggerSubmitButton}
                townAndCountyData={townAndCountyData}
              />
            </AccordionCard>
          </div>
          {renderSubmitButton()}
        </div>
        <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
          <Banner>{getContent()}</Banner>
        </div>
      </div>
      <div className={`${className}__legalTextContainer`}>
        <LegalText insuranceType="home" />
      </div>
    </div>
  );
};

AboutYouComponent.propTypes = {
  latestQuote: PropTypes.any,
  history: PropTypes.any
};

export default AboutYouComponent;
