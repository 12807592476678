/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { basePath, isAnalyticsNotEnabled } from '../constants/commonConstants';
import { makeRequest, sendAnalyticsEvent } from '../helpers';

/* eslint-disable-next-line no-unused-vars, no-empty-pattern */
export const getRenewalQuote = async (homeRenewalData, match, {}) => {
  const quoteRequest = makeRequest({
    method: 'POST',
    url: `${basePath}/home/applied_home_renewal/`,
    payload: homeRenewalData
  });

  return await quoteRequest;
};

export const postHomeQuote = async (
  homeQuoteData,
  cognitoAccessTokenKey,
  props = {}
) => {
  const quoteRequest = await makeRequest({
    method: 'POST',
    url: `${basePath}/home/applied_home_quotes`,
    payload: homeQuoteData,
    history: props.history
  });

  if (isAnalyticsNotEnabled) {
    sendAnalyticsEvent(quoteRequest, 'insuranceEvent', 'Home');
  }
  return quoteRequest;
};

export const getAppliedAddress = async (payloadObj, manual = false) => {
  let manualURL = manual ? 'manual_address' : 'address';
  const quoteRequest = makeRequest({
    method: 'POST',
    url: `${basePath}/home/applied_home_quotes/${manualURL}`,
    payload: {
      ...payloadObj
    }
  });

  return await quoteRequest;
};

export const getTownAndCounty = async () => {
  return makeRequest({
    method: 'GET',
    url: `${basePath}/home/applied_home_quotes/address/code_list`
  });
};
