/* eslint-disable complexity */
/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import './LoginPage.scss';
import '../../styles/commonPageStyles.scss';

import Auth from '@aws-amplify/auth';
import { Button, InputField } from '@boi/core/lib';
import { BOIGroupLogo } from '@boi/core/lib';
import { Form, FormikProps, withFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import type { Location } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { amplifyAnalytics } from '../../amplifyAnalytics';
import { CognitoContext } from '../../CognitoUtils';
import FormHeader from '../../components/FormHeader';
import {
  BOI_WALLET_USER_STORAGE,
  commonFormStylesIdentifier,
  commonPageStylesIdentifier,
  DIRECT_DEBIT_STORAGE
} from '../../constants';
import { USER_NOT_FOUND_EXCEPTION } from '../../constants';
import { FORCE_LOGIN } from '../../constants/home';
import { MFA_TYPE, phoneNoRegex, TYPE_MFA, TYPE_NO_MFA } from '../../constants/loginRegistrationConstants';
import {
  getItemFromSessionStorage,
  getObjectFromSessionStorage,
  isAgent,
  removeAllSessionStorage,
  saveInSessionStorage
} from '../../helpers';
import { routes } from '../../routes';
import { isVerifiedUser, updateUserAttribute } from '../../services/common/commonServices';
import type { CognitoUser } from '../../types';
import { addIrishCountryCode, getCognitoUrl, isLocalhost } from '../../utils/utils';


interface Props {
  email: string;
  password: string;
  location: Location;
  phoneNo: string;
}

interface Values {
  email: string;
  password: string;
  phoneNo: string;
}

const LoginPageForm = (props: Props & FormikProps<Values>) => {
  const {
    dirty,
    errors,
    handleBlur,
    handleChange,
    isValid,
    setFieldError,
    setFieldTouched,
    touched,
    values
  } = props;
  const { setCognitoUser, cognitoUser, setMFAType } = useContext(CognitoContext);
  const [isSendingVerificationCode, setIsSendingVerificationCode] = useState(
    false
  );
  const [sentVerificationEmail, setSentVerificationEmail] = useState(false);
  const [showVerifyEmailOps, setShowVerifyEmailOps] = useState(false);

  const [updatePhoneNumber, setUpdatePhoneNumber] = useState(false);

  const [forceLoginObj, setForceLoginObj] = useState();
  const [quoteData, setQuoteData] = useState();

  const isFieldError = (status: string) => {
    return dirty && touched[status] && errors[status] !== undefined;
  };

  const handleChallenge = (challengeName: string, user: CognitoUser) => {
    if (challengeName === 'SMS_MFA' || challengeName === 'SOFTWARE_TOKEN_MFA') {
      setMFAType(TYPE_MFA);
      if (user.challengeParam.CODE_DELIVERY_DESTINATION.length > 9) {
        props.history.push({ pathname: routes.confirmLoginCode.url, state: { user } });
      } else {
        setUpdatePhoneNumber(true);
      }
    } else if (challengeName === 'NEW_PASSWORD_REQUIRED') {
      // TODO: cognito response for new password is a bit different than what goes into user context, so it is being
      // passed as a state prob, should be in session storage to enable refreshing
      props.history.push({ pathname: routes.newPassword.url, state: { user } });
    } else {
      setMFAType(TYPE_NO_MFA);
      if (forceLoginObj && forceLoginObj.forceLogIn) {
        props.history.push({
          pathname: `${routes.makePayment.url}${forceLoginObj.insuranceType
          }`,
          state: quoteData
        });
      } else {
        props.history.push({ pathname: routes.wallet.url });
      }

    }
  };

  const userSignIn = async (email: string, password: string) => {
    try {
      const user: CognitoUser = await Auth.signIn(email, password);
      user.accessTokenKey = `${user.keyPrefix}.${user.username}.accessToken`;
      setCognitoUser(user);
      saveInSessionStorage(BOI_WALLET_USER_STORAGE, JSON.stringify(user));

      handleChallenge(user.challengeName, user);
      amplifyAnalytics({ name: 'SIGNIN', attributes: { email: email } });
    } catch (error) {
      const errorCode = error?.code || error?.__type;
      switch (true) {
        case errorCode === 'UserNotConfirmedException':
          setShowVerifyEmailOps(true);
          setFieldError(
            'email',
            `Your account needs to be verified. Please check your email for your verification code. Alternatively select 'Resend Verification Code'`
          );
          return;
        case errorCode === 'PasswordResetRequiredException':
          setFieldError(
            'email',
            `If you are an existing customer who has not logged in since 01/08/2023, you may need to reset your password. You can do so by clicking on the “I’ve forgotten my password” link below.`
          );
          return;
        case errorCode === 'NotAuthorizedException':
        case errorCode === 'UserNotFoundException':
          setFieldError(
            'email',
            `User credentials invalid`
          );
          return;
        default: {
          const errorMessage = error.message.replace('username', 'email');
          setFieldError('email', errorMessage);
        }
      }
    }
  };


  const login = async (email: string, password: string) => {
    const isVerified = await isVerifiedUser(email);
    if (!isVerified.number_verified && isVerified.mfa_enabled) {
      setUpdatePhoneNumber(true);
    } else {
      userSignIn(email, password);
    }
  };

  const showResendVerificationCodeButton = () => {
    return showVerifyEmailOps ? (
      <>
        <div className={`${className}__link`}>
          <a id='LoginPage__resendVerificationCodeButton' fluid quaternary onClick={() => {
            resendCode();
          }} disabled={isSendingVerificationCode}>
            Resend Verification Code
          </a>
        </div>
        {showEmailPrompt()}
      </>
    ) : (
      <></>
    );
  };

  const showEmailPrompt = () => {
    return sentVerificationEmail ? (
      <div className={`${className}__resendEmailMessageContainer`}>
        <label className={`${commonFormStylesIdentifier}__infoText`}>
          Email sent. Check your email for the new code.
        </label>
      </div>
    ) : (
      <></>
    );
  };

  const resendCode = async () => {
    if (isSendingVerificationCode) {
      // Prevents the user from spamming the send verification code button
      return;
    }
    Auth.resendSignUp(values.email).then(
      () => {
        setSentVerificationEmail(true);
        setIsSendingVerificationCode(false);
      },
      (error: { [string]: string }) => {
        const msg =
          error.code === USER_NOT_FOUND_EXCEPTION
            ? 'This email is not registered with the system, please sign up for an account first'
            : error.message;
        setFieldError('email', msg);
        setIsSendingVerificationCode(false);
      }
    );
  };


  const renderVerifyOrResetLinks = (email?: string) => {
    return showVerifyEmailOps ? (
      <Link to={{
        pathname: routes.verifyEmail.url,
        state: { email: email }
      }} className={`${className}__link`} data-ga id='LoginPage__verifyEmailLink' data-testid='LoginPage__verifyEmailLink'>
        Verify email
      </Link>
    ) : (
      // If you don't show the verify link show the reset password link
      <Link to={{
        pathname: routes.resetPassword.url,
        state: { resetPassword: true }
      }} className={`${className}__link`} data-ga id='LoginPage__resetPasswordLink' data-testid='LoginPage__resetPasswordLink'>
        I’ve forgotten my password
      </Link>
    );
  };

  const updatePhoneNumberAttribute = async (value: string) => {
    updateUserAttribute(addIrishCountryCode(value), cognitoUser?.username || values.email).then((response: any) => {
      if (response.success || !response.error) {
        userSignIn(values.email, values.password);
      } else {
        setFieldError('email', `Unable to update mobile number please try again after some time or you can call our customer care center at 01 488 4062`);
      }
    }).catch((e: any) => {
      console.error(e);
    });
  };

  useEffect(() => {
    if (forceLoginObj) {
      setQuoteData(getObjectFromSessionStorage(`${forceLoginObj.insuranceType}${DIRECT_DEBIT_STORAGE}`));
    }
  }, [forceLoginObj]);

  useEffect(() => {
    if (cognitoUser?.challengeName === MFA_TYPE || JSON.parse(getItemFromSessionStorage(BOI_WALLET_USER_STORAGE))?.challengeName === MFA_TYPE) {
      Auth.signOut()
        .then(() => {
          setCognitoUser(null);
          removeAllSessionStorage();
        })
        .catch(() => console.error('An error occurred while logging out'));
    }
    if (isAgent() && !cognitoUser) {
      const url = getCognitoUrl(isLocalhost);
      window.location = url;
    }
    setForceLoginObj(getObjectFromSessionStorage(FORCE_LOGIN));
  }, []);

  const className = 'c-LoginPage';
  const agentText = 'Redirecting Agent to Single Sign On Page';
  return (
    <div className={`${className}`}>
      <div className={`${className}__container`}>
        <div className={`${className}__innerContent`}>
          <div className={`${commonPageStylesIdentifier}__logo--flex`}>
            <BOIGroupLogo />
          </div>
          {isAgent() && (
            <>
              <div className={`${commonPageStylesIdentifier}`}>
                <div className={`${commonPageStylesIdentifier}__desktopTitle`}>
                  {agentText}
                </div>
              </div>
            </>
          )}
          {!isAgent() && (
            <>
              <FormHeader className={className} titleText={'Log In'} />
              <Form autoComplete={'off'}>

                <>
                  {!updatePhoneNumber && (
                    <>
                      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
                        <label htmlFor='email' className={`${commonFormStylesIdentifier}__fieldLabel`}>
                          Email address
                        </label>

                        <span className={`${className}__input`}>
                        <InputField error={isFieldError('email')}
                                    errorMessage={errors.email}
                                    name='email'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder='Enter email address'
                                    touched={touched.email}
                                    type='email'
                                    value={values.email} />
                      </span>
                      </div>
                      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
                        <label htmlFor='password' className={`${commonFormStylesIdentifier}__fieldLabel`}>
                          Password
                        </label>
                        <span className={`${className}__input`}>
                        <InputField error={isFieldError('password')}
                                    errorMessage={errors.password}
                                    name='password'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder='Enter password'
                                    touched={touched.password}
                                    type='password'
                                    autoComplete={'off'}
                                    value={values.password} />
                      </span>
                      </div>
                      <div className={`${className}__button`}>
                        <Button id='LoginPage__loginButton' fluid quaternary disabled={!isValid} onClick={async () => {
                          setFieldTouched('email', true);
                          await login(values.email, values.password);
                        }} type='submit'>
                          Login
                        </Button>
                      </div>
                    </>
                  )}
                  {updatePhoneNumber && (
                    <>
                      <div className={`${commonFormStylesIdentifier}__fieldContainer ${className}__spaceTop`}>
                        <label htmlFor='phoneNo' className={`${commonFormStylesIdentifier}__fieldLabel`}>
                          Please set your mobile number
                        </label>
                        <div className={`${commonFormStylesIdentifier}__infoText`}>
                          To keep your account safe, you must setup Multi Factor Authentication.
                          Multifactor Authentication uses an additional verification method at login
                          to verify your identity. You can set this up below by entering a valid
                          mobile number to receive a code by SMS, which can then be entered on the
                          next screen.
                        </div>
                        <span className={`${className}__input ${className}__spaceTop`}>
                              <InputField error={isFieldError('phoneNo')}
                                          errorMessage={errors.phoneNo}
                                          name='phoneNo'
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          placeholder='Enter mobile number'
                                          touched={touched.phoneNo}
                                          type='text'
                                          value={values.phoneNo} />
                            </span>
                      </div>
                      <div className={`${className}__button`}>
                        <Button id='LoginPage__loginButton' fluid quaternary disabled={errors.phoneNo} onClick={() => {
                          updatePhoneNumberAttribute(values.phoneNo);
                        }} type='button'>
                          Next
                        </Button>
                      </div>
                    </>
                  )}
                </>
              </Form>
              {showResendVerificationCodeButton()}
              {renderVerifyOrResetLinks(values.email)}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const LoginPage = withFormik<Props, Values>({
  mapPropsToValues(props: Props): {} {
    let initialValues = {
      email: '',
      password: '',
      phoneNo: ''
    };
    if (props.location && props.location.state && props.location.state.email) {
      initialValues.email = props.location.state.email;
    }
    const valuesObject = {};
    Object.keys(initialValues).forEach((key: string) => {
      valuesObject[key] = initialValues[key];
    });
    return valuesObject;
  },
  handleSubmit(values: Values, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(12, 'Requires a minimum of 12 characters'),
    phoneNo: yup
      .string()
      .matches(phoneNoRegex, 'Must be a valid mobile number')
      .test('valid-phone', 'Please enter a valid mobile number', function(
        value: string
      ): boolean {
        if (!this.parent.updatePhoneNumber) {
          return true;
        }
        return phoneNoRegex.test(value);
      })
  }),
  displayName: 'LoginPageForm'
})(LoginPageForm);

export default LoginPage;
