/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './InstallPrompt.scss';

import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// BOILink probaly has to be renamed at somepoint
// It should also use react-router link under the hood
import React, { useEffect, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';

import iphoneShareIcon from '../../assets/icons8-share-rounded-30.png';
import { primaryBlue } from '../../constants/colors';
import BoiNotificationCard from '../BoiNotificationCard';

const className = `c-InstallPrompt`;

const InstallPrompt = () => {
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const installPromptHeading = () => {
    return (
      <div className={`${className}__installPromptHeading`}>
        Install the App now!
      </div>
    );
  };

  const handleImageClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(() => {
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: any) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      );
    };
  }, []);

  useEffect(() => {
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      'standalone' in window.navigator && window.navigator.standalone;
    if (isMobile && !isInStandaloneMode()) {
      setShowInstallPrompt(true);
    }
  }, []);

  if (!showInstallPrompt) return null;
  return (
    <div className={className}>
      <BoiNotificationCard color={primaryBlue} heading={installPromptHeading}>
        {isIOS ? (
          <span className={`${className}__installPromptMessage`}>
            <p className={`${className}__installPromptTextIphone`}>
              To install the app, tap
            </p>
            <img
              src={iphoneShareIcon}
              className={`${className}__installPromptImageIphone`}
              onClick={handleImageClick}
            />
            <p className={`${className}__installPromptText`}>
              {
                "(usually located at the bottom of your screen), then select 'Add to Homescreen'"
              }
            </p>
          </span>
        ) : (
          <span className={`${className}__installPromptMessage`}>
            <p className={`${className}__installPromptText`}>
              Click on the install icon below, to install the app.
            </p>
            <FontAwesomeIcon
              icon={faDownload}
              className={`${className}__installPromptImage`}
              onClick={handleImageClick}
              size="2x"
            />
          </span>
        )}
      </BoiNotificationCard>
    </div>
  );
};

export default InstallPrompt;
