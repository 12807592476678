/* eslint-disable react/prop-types */
/* eslint-disable flowtype/require-parameter-type */
// @flow

import './MobileHeader.scss';

import { BOIWhiteLogo } from '@boi/core/lib';
import { faSignInAlt, faSignOutAlt,faUser, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext,useEffect,useState } from 'react';
import { withRouter } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { darkBlue, greyDark } from '../../constants/colors';
import { isAgent } from '../../helpers';
import { getAgentName } from '../../helpers/AgentCheck';
import { routes } from '../../routes';
import Navigation from '../Navigation';
import PopOverWrapper from '../PopOverWrapper/PopOverWrapper';

const MobileHeader = ({history}) => {
  const className = 'c-MobileHeader';
  const { cognitoUser } = useContext(CognitoContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [popOverVisible, setPopOverVisible] = useState(true)

  const getLoggedInNavigationItems = () => {
    return [
      [
        { displayText: 'Wallet', link: routes.wallet.url },
        { displayText: 'Manage my insurance', link: routes.manageMyInsuranceDashboard.url }
      ],
      // [{ displayText: 'Help', link: routes.comingSoon.url }],
      [{ displayText: 'Logout', link: routes.logoutPage.url }]
    ];
  };

  const getLoggedOutNavigationItems = () => {
    return [
      [
        { displayText: 'Wallet', link: routes.wallet.url },
        { displayText: 'Manage my insurance', link: routes.loginPage.url }
      ],
      // [{ displayText: 'Help', link: routes.comingSoon.url }],
      [{ displayText: 'Login', link: routes.loginPage.url }]
    ];
  };

  const navigationItems = isLoggedIn ? getLoggedInNavigationItems() : getLoggedOutNavigationItems();
  const reRoute = (action: string) => {
    switch(action) {
      case 'login':
        history.push(routes.loginPage.url) 
        break
      case 'logout':
        history.push(routes.logoutPage.url) 
        break
      case 'signIn':
        history.push(routes.signUp.url) 
        break
    }
    setPopOverVisible(false)
  }

  // eslint-disable-next-line complexity
  const menuItems = () => {
    return (
      <>
        <div className={`${className}__menuItemGrid`} onClick={() => {reRoute(!isLoggedIn ? 'login' : 'logout')}}>
            <div>
              {!isLoggedIn && !isAgent() ? <span>Log In</span> : <span>Logout</span>}
              {!isLoggedIn && isAgent() ? <span>Logout</span> : <></>}
            </div>
            <div>
              {!isAgent() && (<FontAwesomeIcon icon={isLoggedIn ? faSignOutAlt : faSignInAlt} size="1x" color={greyDark} />)}
              {(isAgent() && isLoggedIn) && (<FontAwesomeIcon icon={faSignOutAlt} size="1x" color={greyDark} />)}
            </div>       
        </div>
        {(!isLoggedIn && !isAgent()) &&
          (
            <div className={`${className}__menuItemGrid`} onClick={() => {reRoute('signIn')}}>
                <div>
                  <span>Sign Up</span>
                </div>
                <div>
                  <FontAwesomeIcon icon={faUser} size="1x" color={greyDark} />
                </div>       
            </div>
          )}
      </>
    )
  }

  const titleHeader = () => {
    return (
      <>
        {!isLoggedIn && 
          (
          <FontAwesomeIcon className={`${className}__navBarIcon`} icon={faUserCircle} size="1x" color={greyDark} />
          )
        }
        {isLoggedIn && 
          (
            <><span className={`${className}__navBarUserCircleIcon`}>{cognitoUser?.attributes?.given_name || getAgentName(cognitoUser)}</span><FontAwesomeIcon className={`${className}__navBarIcon`} icon={faUserCircle} size="1x" color={greyDark} /></>
          )
        }
      </>
    )
  }

  useEffect(() => {
    setIsLoggedIn(!!cognitoUser)
 }, [cognitoUser])

  return (
    <div className={`${className}`}>
      <div className={`${className}__menuItems ${className}${(isLoggedIn ? '__loggedInMenuItem' : '__nonLoggedInMenuItem')}`}>
        <div className={`${className}__popOverMenuItems`}>
          <PopOverWrapper
            clickTitle={titleHeader()}
            visiblility={popOverVisible}
            updateVisibility={() => {
              setPopOverVisible(true)
            }}
          >
            {menuItems()}
          </PopOverWrapper>
        </div>
        </div>
        <div className={`${className}__logo`}>
          <BOIWhiteLogo color={darkBlue} />
        </div>
        <div className={`${className}__barIcon`}>
          <Navigation menuItems={navigationItems} />
        </div>


    </div>
  );
};

export default withRouter(MobileHeader);