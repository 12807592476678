/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './PaymentsThankYouPage.scss';

import { Button, CardWrapper, Title } from '@boi/core/lib';
import { colors } from '@boi/core/lib/styles';
// $FlowFixMe[incompatible-import]
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import React, { useContext, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { backToHome } from '../../constants/buttonLabelConstants';
import {
  CAR_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import {
  AUTO_RENEWAL_EXISTING_PAYMENT,
  POLICY_RENEWAL
} from '../../constants/sessionStorage/genericStorageIdentifiers';
import {
  getItemFromSessionStorage,
  getObjectFromSessionStorage,
  removeCarJourneyStorage,
  removeHomeJourneyStorage,
  removeTravelJourneyStorage
} from '../../helpers';
import { routes } from '../../routes';
import UserContext from '../../UserContext';

type SuccessProps = {
  quote_reference: string,
  insuranceType: string
};

const className = 'c-PaymentsThankYouPage';
const classes = 'c-ThankYouPageSuccess';

const RSAContentText = `We will issue your policy schedule along with other \
related documents in the post, you can expect to receive these in the \
coming days.`;

const ChubbContentText = `We'll email you when your policy is ready. You can \
view your welcome documents pack now`;

const registrationPropmpt = `To access your policy documents you will need \
to ensure that you register your account, please select the 'register now' \
option below, alternatively we have emailed you the relevant link. `;

const thankYouTextHome = `You have successfully purchased Home Insurance.`;
const contentTexts = {
  car: RSAContentText,
  travel: ChubbContentText
};

const clearStorageFunctions = {
  [HOME_INSURANCE_TYPE]: removeHomeJourneyStorage,
  [CAR_INSURANCE_TYPE]: removeCarJourneyStorage,
  [TRAVEL_INSURANCE_TYPE]: removeTravelJourneyStorage
};
// eslint-disable-next-line complexity
const Success = ({ quote_reference, insuranceType }: SuccessProps) => {
  const { getUserName } = useContext(UserContext);
  const { cognitoUser } = useContext(CognitoContext);
  const [showViewDocuments, setShowViewDocuments] = useState(false);

  let autoRenewal = null;

  const policyRenewal = getObjectFromSessionStorage(POLICY_RENEWAL) || null;
  if (policyRenewal?.renewal) {
    autoRenewal = JSON.parse(
      getItemFromSessionStorage(AUTO_RENEWAL_EXISTING_PAYMENT)
    );
  }

  const getTitleText = () => {
    const name = getUserName();
    return name ? `Thank you ${name}.` : 'Thank you!';
  };

  const isLoggedIn = !!cognitoUser;
  //survey removed for the moment date 17th October 2019
  // const showFeedbackLink = insuranceType !== 'travel';

  const goToViewDocumentsPage = () => {
    setShowViewDocuments(true);
  };

  const defaultContentText =
    `Your policy reference number is: ${quote_reference}. ` +
    `You can view your welcome pack now in your wallet.`;

  // eslint-disable-next-line complexity
  const HomeContentTwoText = () => {
    switch (true) {
      case !isLoggedIn:
        return `Your policy reference number is ${quote_reference}. Don't forget to select the 'register now' option below to avail of future enhancements, alternatively we have emailed you the relevant link. Please register with the same email address as you have used in your quote.`;
      case autoRenewal === true:
        return (
          <>
            Your policy is currently set to Renew on Direct Debit on the{' '}
            <strong>
              {format(policyRenewal?.start_date, 'Do MMMM YYYY') || ''}
            </strong>{' '}
            so no further action from you is required.
          </>
        );
      case policyRenewal?.renewal === true:
        return '';
      default:
        return thankYouTextHome;
    }
  };

  const contentTwoTexts = {
    car: defaultContentText,
    travel: defaultContentText
  };

  // eslint-disable-next-line complexity
  const renderHomeOnlineText = () => {
    if (insuranceType === HOME_INSURANCE_TYPE && autoRenewal) {
      return (
        <div className={`${className}__contentText`}>
          Your policy documents will be available to view online on{' '}
          <strong>
            {format(policyRenewal?.start_date, 'Do MMMM YYYY') || ''}
          </strong>{' '}
          through ‘Manage my insurance’.
        </div>
      );
    } else if (
      insuranceType === HOME_INSURANCE_TYPE &&
      !autoRenewal &&
      !policyRenewal?.renewal
    ) {
      return (
        <div className={`${className}__contentTexthome`}>
          Your policy number is: <br />
          <strong>{quote_reference}</strong>.
        </div>
      );
    }
  };

  const renderRegisteredText = () =>
    isLoggedIn && insuranceType !== HOME_INSURANCE_TYPE ? (
      <div className={`${className}__contentText`}>
        {contentTexts[insuranceType]}
      </div>
    ) : (
      <></>
    );

  const renderUnregistredText = () =>
    !isLoggedIn ? (
      <div className={`${className}__contentText`}>{registrationPropmpt}</div>
    ) : (
      <></>
    );

  const renderRegisterButton = () =>
    !isLoggedIn ? (
      <Link to={`${routes.signUp.url}`}>
        <Button fluid data-ga id="PaymentsThankYouPage__registerNowButton">
          Register Now
        </Button>
      </Link>
    ) : (
      <></>
    );

  //survey removed for the moment date 17th October 2019
  // const renderFeedbackButton = () =>
  //   isLoggedIn && showFeedbackLink ? (
  //     <div className={`${className}__buttonSpacing`}>
  //       <Link to={`${routes.surveyPage.url}${insuranceType}`}>
  //         <Button
  //           quaternary
  //           fluid
  //           data-ga
  //           id="PaymentsThankYouPage__giveFeedbackButton"
  //         >
  //           Give Feedback
  //         </Button>
  //       </Link>
  //     </div>
  //   ) : <></>;

  const renderDocumentsButton = () =>
    isLoggedIn ? (
      <div className={`${className}__buttonSpacing`}>
        <Button
          fluid
          data-ga
          id="PaymentsThankYouPage__viewDocumentsButton"
          onClick={goToViewDocumentsPage}
        >
          View Insurance Documents
        </Button>
      </div>
    ) : (
      <></>
    );

  const renderDefaultBody = () => (
    <div className={`${classes}`}>
      <CardWrapper>
        <div className={className}>
          <div className={`${className}__thankYouPageTextContainer`}>
            {insuranceType === HOME_INSURANCE_TYPE ? (
              <div className={`${className}__primaryTextContainer`}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className={`${className}--buttonIcon fa-lg`}
                  color={colors.primaryColor}
                  size="3x"
                />
              </div>
            ) : (
              <></>
            )}
            <div className={`${className}__primaryTextContainerOne`}>
              <Title type="h1" weight="light">
                <span id="PaymentsThankYouPage__thankYouText">
                  {getTitleText()}
                </span>
              </Title>
            </div>
            <div className={`${className}__primaryTextContainerTwo`}>
              <Title type="h1" weight="light">
                <span id="PaymentsThankYouPage__youAreCoveredText">
                  You&#39;re covered.
                </span>
              </Title>
            </div>
            <div
              className={
                policyRenewal?.renewal
                  ? `${className}__contentText`
                  : `${className}__contentText${insuranceType}`
              }
            >
              {insuranceType === HOME_INSURANCE_TYPE
                ? HomeContentTwoText()
                : contentTwoTexts[insuranceType]}
            </div>
            {renderRegisteredText()}
            {renderUnregistredText()}
            {renderHomeOnlineText()}
          </div>
          <div className={`${className}__buttonContainer`}>
            {renderRegisterButton()}
            {/*
            survey removed for the moment date 17th October 2019
            {renderFeedbackButton()} 
            */}
            {renderDocumentsButton()}
            <div className={`${className}__buttonSpacing`}>
              <Link to={routes.wallet.url}>
                <Button secondary fluid data-ga id="PaymentsThankYouPage__home">
                  {backToHome}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </CardWrapper>
    </div>
  );

  if (showViewDocuments) {
    return <Redirect push to={`${routes.policyLinks.url}${insuranceType}`} />;
  }

  if (clearStorageFunctions[insuranceType]) {
    clearStorageFunctions[insuranceType]();
  }

  return renderDefaultBody();
};

export default Success;
