/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import '../LoginPage/LoginPage.scss';
import '../../styles/commonPageStyles.scss';

import Auth from '@aws-amplify/auth';
import { Button, InputField } from '@boi/core/lib';
import { BOIGroupLogo } from '@boi/core/lib';
import { Form, FormikProps, withFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import type { Location } from 'react-router-dom';
import * as yup from 'yup';

import { CognitoContext } from '../../CognitoUtils';
import FormHeader from '../../components/FormHeader';
import {
  BOI_WALLET_USER_STORAGE,
  commonFormStylesIdentifier,
  commonPageStylesIdentifier,
  DIRECT_DEBIT_STORAGE
} from '../../constants';
import { FORCE_LOGIN } from '../../constants/home';
import { MFA_TYPE } from '../../constants/loginRegistrationConstants';
import { getObjectFromSessionStorage } from '../../helpers';
import { routes } from '../../routes';

interface Props {
  location: Location;
}

interface Values {
  smsCodeUserInput: string;
}



const ConfirmLoginPageForm = (props: Props & FormikProps<Values>) => {
  const {
    errors,
    handleBlur,
    handleChange,
    isValid,
    touched,
    setFieldError,
    values
  } = props;
  const [quoteData, setQuoteData] = useState();
  const [forceLoginObj, setForceLoginObj] = useState();
  const [userData, setUserData] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const className = 'c-LoginPage';

  const { getUser } = useContext(CognitoContext);

  const isFieldError = (status: string) => {
    return touched[status] && errors[status] !== undefined;
  };

  // eslint-disable-next-line complexity
  const handleConfirmCode = (challengeAnswer: string) => {

    const errorHandler = (error: any) => {
      if (error?.code === 'CodeMismatchException') {
        setFieldError('smsCodeUserInput', 'Please enter valid code');
        return;
      }
      setFieldError('smsCodeUserInput', 'Please contact 1890 604 604 for support or try log in after sometime');
    }

    if (userData?.accessTokenKey || props?.location?.state?.user) {
      Auth.confirmSignIn(
        userData || props?.location?.state?.user,
        challengeAnswer,
        MFA_TYPE
      )
        .then(() => {
          getAuthenticated()
          if (forceLoginObj && forceLoginObj.forceLogIn) {
            props.history.push({
              pathname: `${routes.makePayment.url}${forceLoginObj.insuranceType
                }`,
              state: quoteData
            });
          } else {
            props.history.push(routes.wallet.url);
          }
        })
        .catch((error: any) => {
          errorHandler(error)
        });
    }
  };

  const getAuthenticated = async () => {
    await getUser()
  }

  useEffect(() => {
    const number = userData?.challengeParam?.CODE_DELIVERY_DESTINATION
    if (number && number.length > 0) {
      const lastTowDigits = number.slice(-2)
      const hiddenDigits = '*'.repeat(7)
      setPhoneNumber(hiddenDigits + lastTowDigits)
    }
  }, [userData])

  // eslint-disable-next-line complexity
  useEffect(() => {
    const userDetails = getObjectFromSessionStorage(BOI_WALLET_USER_STORAGE);
    if (props?.location?.state?.user || userDetails) {
      setUserData(props?.location?.state?.user || userDetails);
    }
    setForceLoginObj(getObjectFromSessionStorage(FORCE_LOGIN));
  }, []);

  useEffect(() => {
    if (forceLoginObj) {
      setQuoteData(getObjectFromSessionStorage(`${forceLoginObj.insuranceType}${DIRECT_DEBIT_STORAGE}`));
    }
  }, [forceLoginObj]);

  return (
    <>
      <div className={`${className}`}>
        <div className={`${className}__container`}>
          <div className={`${className}__innerContent`}>
            <div className={`${commonPageStylesIdentifier}__logo--flex`}>
              <BOIGroupLogo />
            </div>
          </div>
        </div>
      </div>
      <div className={`${className}__confirmLoginPageForm`}>
        <FormHeader
          classes={`${className}__confirmLoginFormSet`}
          className={className}
          titleText={'A verification code has been sent to your device'}
        />
        <Form autoComplete={'off'} className={``}>
          <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
            <label
              htmlFor="smsCodeUserInput"
              className={`${commonFormStylesIdentifier}__fieldLabel`}
            >
              {`Please enter the 6 digit code that has been sent via SMS to your mobile number ending in ${phoneNumber}`}
            </label>
            <span className={`${className}__input`}>
              <InputField
                error={isFieldError('smsCodeUserInput')}
                errorMessage={errors.smsCodeUserInput}
                name="smsCodeUserInput"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Enter code"
                touched={touched.smsCodeUserInput}
                type="text"
                autoComplete={'off'}
                value={values.smsCodeUserInput}
              />
            </span>
          </div>
          <div className={`${className}__button`}>
            <Button
              id="LoginPage__loginButton1"
              fluid
              quaternary
              disabled={!isValid}
              onClick={() => {
                handleConfirmCode(values.smsCodeUserInput);
              }}
              type="submit"
            >
              Confirm Code
            </Button>
          </div>
          <div className={`${className}__paragraph`}>
            <p>If your mobile number has changed since you set-up multi-factor authentication, please call us on <a href="tel:01-488 4062">01-488 4062</a></p>
          </div>
        </Form>
      </div>
    </>
  );
};

const ConfirmLoginPage = withFormik < Values > ({
  mapPropsToValues(): {} {
    let initialValues = {
      smsCodeUserInput: ''
    };
    const valuesObject = {};
    Object.keys(initialValues).forEach((key: string) => {
      valuesObject[key] = initialValues[key];
    });
    return valuesObject;
  },
  handleSubmit(values: Values, { setSubmitting }: FormikProps) {
    setSubmitting(false);
  },
  validationSchema: yup.object().shape({
    smsCodeUserInput: yup
      .string()
      .required('Sms code is required')
      .min(6, 'Requires 6 digit code')
      .max(6, 'Requires 6 digit code')
  }),
  displayName: 'ConfirmLoginPageForm'
})(ConfirmLoginPageForm);

export default ConfirmLoginPage;
