// @flow

import './Navigation.scss';

import { HamburguerMenu } from '@boi/core/lib';
import React, { useState } from 'react';

import { greyDark } from '../../constants/colors';
import NavigationGroup from './NavigationGroup';
import { NavigationItem } from './NavigationItem';
import NavigationLogo from './NavigationLogo'

const getNavigationGroup =
  (items: Array<NavigationItem>, index: number) => (
    <NavigationGroup id={`NavigationGroup-${index}`} data-testid={`NavigationGroup-${index}`}
      key={`nagivation-group-${index}`} menuItems={items} />
  );

interface Props {
  menuItems: Array<Array<NavigationItem>>
}

const Navigation = ({ menuItems }: Props) => {
  const [isOpen, toggleOpen] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const className = 'c-Navigation';

  const setMenuState = (state: boolean) => {
    if (isDisabled) return;
    setDisabled(true);
    setTimeout(() => setDisabled(false), 500);
    toggleOpen(state);
  };

  const openMenu = () => setMenuState(true);

  const closeMenu = () => setMenuState(false);

  return (
    <div>
      <div onClick={openMenu} id="openMenu">
        <HamburguerMenu color={greyDark} />
      </div>
      <div className={`${className} ${isOpen ? 'c-Navigation--open' : ''}`}
        onClick={closeMenu} id="Navigation">
        <div className={`${className}__overlay ${isOpen ? 'c-Navigation__overlay--open' : ''}`}>
          <div className={`${className}__content ${isOpen ? 'c-Navigation__content--open' : ''}`}>
            <div className={`${className}__contentGroup`}>
              <div className={`${className}--with-padding`}>
                <div className="c-MobileHeader__barIcon"
                  id="closeMenu">
                  <HamburguerMenu color={greyDark} />
                </div>
              </div>

              <div className={`${className}--with-padding ${className}__contentGroup ${className}--blue`}>
                Menu
              </div>

              {menuItems.map(getNavigationGroup)}
            </div>
            <div className={`${className}__boiLogo ${className}--with-padding`}>
              <NavigationLogo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
