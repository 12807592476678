/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import { basePath } from '../../constants/commonConstants';
import { makeRequest, sendAnalyticsEvent } from '../../helpers';

export const postMotorQuote = async (motorQuoteData, props = {}) => {
  const quoteRequest = await makeRequest({
    method: 'POST',
    url: `${basePath}/motor/motor_quotes`,
    payload: motorQuoteData,
    history: props.history
  });
  sendAnalyticsEvent(quoteRequest, 'insuranceEvent', 'Car');
  return quoteRequest;
};
