//@flow
import React from 'react';

import AboutYouFormWrapper from '../components/Home/AboutYouForm/AboutYouFormWrapper';
import YourCoverComponent from '../components/Home/YourCoverForm/YourCoverComponent';
import YourHomeComponent from '../components/Home/YourHomeForm/YourHomeComponent';
import SearchAllQuotes from '../components/SearchAllQuotes/SearchAllQuotes';
import { AmendPolicyPage } from '../pages/AmendPolicyPage';
import { BreakdownAssistance } from '../pages/BreakdownAssistance';
import { ComingSoon } from '../pages/ComingSoon';
import { ConfirmLoginPage } from '../pages/ConfirmLoginPage';
import { DocumentsPage } from '../pages/Documents';
import { ForceLogIn } from '../pages/ForceLogIn';
import { GenericErrorPage } from '../pages/GenericErrorPage';
import { GetGenericQuotePage } from '../pages/GetGenericQuotePage';
import { GetQuotePage } from '../pages/GetQuotePage';
import { GetQuoteThankYouGenericPage } from '../pages/GetQuoteThankYouGenericPage';
import { GetQuoteThankYouPage } from '../pages/GetQuoteThankYouPage';
import HomeTermsAndConditionsPage from '../pages/HomeTermsAndConditionsPage';
import { LoginPage } from '../pages/LoginPage';
import { Logout } from '../pages/Logout';
import { ManageInsuranceProductPage } from '../pages/ManageInsuranceProductPage';
import { ManageMyInsuranceDashboard } from '../pages/ManageMyInsuranceDashboard';
import { MarketingConsentForm } from '../pages/MarketingConsentForm';
import { MedicalAssistance } from '../pages/MedicalAssistance';
import MotorTermsAndConditionsPage from '../pages/MotorTermsAndConditionsPage';
import { MotorViewQuote } from '../pages/MotorViewQuote';
import { NewPassword } from '../pages/NewPassword';
import PaymentsPage from '../pages/PaymentsPage';
import { PaymentsThankYouPage } from '../pages/PaymentsThankYouPage';
import { PolicyLinksPage } from '../pages/PolicyLinksPage';
import { ProductLandingPage } from '../pages/ProductLandingPage';
import { RenewPolicyPage } from '../pages/RenewPolicyPage';
import { ResetPassword } from '../pages/ResetPassword';
import Signup from '../pages/Signup';
import StatementOfFact from '../pages/StatementOfFact/StatementOfFact';
import { SubmitClaimPage } from '../pages/SubmitClaimPage';
import TravelDeclaration from '../pages/TravelDeclaration/TravelDeclaration';
import VerifyEmail from '../pages/VerifyEmail';
import ViewHomeMultipleQuotesPage from '../pages/ViewHomeMultipleQuotesPage/ViewHomeMultipleQuotesPage';
import ViewMultiplePoliciesPage from '../pages/ViewMultiplePoliciesPage';
import ViewMultipleQuotesPage from '../pages/ViewMultipleQuotesPage';
import { ViewQuote } from '../pages/ViewQuote';
import ViewTravelQuotePage from '../pages/ViewTravelQuotePage';
import WalletLandingPage from '../pages/WalletLandingPage';
import { routes } from '../routes';
import type { RouterPropsType } from '../types/commonTypes';

const renderWallet = (props: RouterPropsType) => (<WalletLandingPage {...props} />);

export default {
  loadUserRoutes: [
    {
      path: `${routes.productLandingPage.url}:type(home|travel|car)`,
      component: ProductLandingPage
    },
    {
      path: `${routes.marketingConsent.url}:type(home|car|travel)`,
      component: MarketingConsentForm
    },
    {
      path: `${routes.getQuotePage.url}:type(home)`,
      component: GetQuotePage
    },
    {
      path: `${routes.getQuotePage.url}:type(aboutYou)`,
      component: AboutYouFormWrapper
    },
    {
      path: `${routes.getQuotePage.url}:type(yourHome)`,
      component: YourHomeComponent
    },
    {
      path: `${routes.getQuotePage.url}:type(yourCover)`,
      component: YourCoverComponent
    },
    {
      path: `${routes.getGenericQuotePage.url}:type(travel|car)`,
      component: GetGenericQuotePage
    },
    {
      path: `${routes.getQuoteThankYouPage.url}:type(home)`,
      component: GetQuoteThankYouPage
    },
    {
      path: `${routes.viewQuote.url}:type(home)`,
      component: ViewQuote
    },
    {
      path: `${routes.viewQuote.url}:type(car)`,
      component: MotorViewQuote
    },
    {
      path: `${routes.paymentThankYou.url}:type(home|travel|car)`,
      component: PaymentsThankYouPage
    },
    {
      path: `${routes.viewMultipleQuotes.url}:type(car)`,
      component: ViewMultipleQuotesPage
    },
    {
      path: `${routes.viewMultipleQuotes.url}:type(home)`,
      component: ViewHomeMultipleQuotesPage
    },
    {    //TODO: this code is used for demo purpose and removed once api integration would be set in
      path: `${routes.viewRenewalQuote.url}:type(home|homeDD|homeOther)`,
      component: ViewHomeMultipleQuotesPage
    },
    {
      path: `${routes.viewMultipleQuotes.url}:type(travel)`,
      component: ViewTravelQuotePage
    },
    {
      path: `${routes.getQuoteThankYouPage.url}:type(travel|car)`,
      component: GetQuoteThankYouGenericPage
    },
    {
      path: `${routes.getDeclarationPage.url}:type(travel)`,
      component: TravelDeclaration
    },
    {
      path: `${routes.makePayment.url}:type(home|travel|car)`,
      component: PaymentsPage
    },
    {
      path: `${routes.termsAndConditions.url}:type(home)`,
      component: HomeTermsAndConditionsPage
    },
    {
      path: `${routes.termsAndConditions.url}:type(car)`,
      component: MotorTermsAndConditionsPage
    },
    {
      path: `${routes.forceLogin.url}:type(home|travel|car)`,
      component: ForceLogIn
    }
  ],
  privateRoutes: [
    {
      path: routes.documents.url,
      component: DocumentsPage
    },
    {
      path: `${routes.manageMyInsuranceProduct.url}:type(home|travel|car)`,
      component: ManageInsuranceProductPage
    },
    {
      path: `${routes.renewPolicyPage.url}:type(home|travel|car)`,
      component: RenewPolicyPage
    },
    {
      path: `${routes.amendPolicyPage.url}:type(home|travel|car)`,
      component: AmendPolicyPage
    },
    {
      path: `${routes.submitClaimPage.url}:type(home|travel|car)`,
      component: SubmitClaimPage
    },
    {
      path: `${routes.medicalAssistance.url}:type(travel)`,
      component: MedicalAssistance
    },
    {
      path: `${routes.breakdownAssistance.url}:type(car)`,
      component: BreakdownAssistance
    },
    {
      path: `${routes.policyLinks.url}:type(home|travel|car)`,
      component: PolicyLinksPage
    },
    {
      path: routes.manageMyInsuranceDashboard.url,
      component: ManageMyInsuranceDashboard
    },
    {
      path: `${routes.genericErrorPage.url}:type(home|travel|car)`,
      component: GenericErrorPage
    },
    {
      path: `${routes.viewMultiplePolicies.url}:type(home|car|travel)`,
      component: ViewMultiplePoliciesPage
    },
    {
      path: `${routes.statementOfFact.url}:type(home)`,
      component: StatementOfFact
    }
  ],
  routes: [
    {
      path: routes.wallet.url,
      render: renderWallet
    },
    { path: routes.comingSoon.url, component: ComingSoon },
    { path: routes.loginPage.url, component: LoginPage },
    { path: routes.confirmLoginCode.url, component: ConfirmLoginPage },
    { path: routes.logoutPage.url, component: Logout },
    { path: routes.newPassword.url, component: NewPassword },
    { path: routes.resetPassword.url, component: ResetPassword },
    { path: routes.signUp.url, component: Signup },
    { path: routes.verifyEmail.url, component: VerifyEmail },
    {path: routes.searchAllQuotes.url, component: SearchAllQuotes}
  ]
};
