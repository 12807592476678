"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = XIcon;

var _react = require("react");

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function XIcon() {
  return _react2.default.createElement("svg", {
    viewBox: "0 0 20 20",
    preserveAspectRatio: "none",
    width: 12,
    fill: "transparent",
    stroke: "#979797",
    strokeWidth: "1.1px"
  }, _react2.default.createElement("path", {
    d: "M1,1 L19,19"
  }), _react2.default.createElement("path", {
    d: "M19,1 L1,19"
  }));
}